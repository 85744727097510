export const HOBBIES = [
  "Speeddater",
  "Beekeeper",
  "Tik Tok Dancer",
  "Reddit Lurker",
  "Stack Overflow Expert",
  "Professional Sleeper",
  "Drying Paint Watcher",
  "Snake Milker",
  "Dog Food Taster",
  "Odor Judge",
  "Bingo Manager",
  "Hippotherapist",
  "Veterinary Acupuncturist",
  "Snake Chiropractor",
  "Flavorist",
  "Online Dating Ghostwriter",
  "Color Expert",
  "Full-Time Netflix Viewer",
  "Train Pusher",
  "Professional Mourner",
  "Scuba Diving Pizza Delivery Person",
  "Toy Maker",
  "Voice-Over Artist",
  "Professional Pusher of People into Train Cars",
  "Water Slide Tester",
  "Paper Towel Sniffer",
  "Ostrich Babysitter",
  "Furniture Tester",
  "Bike Courier",
  "Body Painter",
  "Feng Shui Consultant",
  "Fragrance Chemist",
  "Nail Polish Namer",
  "Online Reviewer",
  "Ethical Hacker",
  "Iceberg Mover",
  "Dog Surfing Instructor",
  "Panda Nanny",
  "Professional Mermaid",
  "Fortune Cookie Writer",
  "Legal Bank Robber",
  "Teddy Bear Surgeon",
  "Zombie",
  "3D Printer",
  "Acroyogi",
  "Actor",
  "Aerial Silk Artist",
  "Amateur Radio Host",
  "Aquascaper",
  "Astrologiest",
  "Baton Twirler",
  "Beatboxer",
  "Binge-watcher",
  "Book Restorationist",
  "Martial Artist",
  "Breadmaker",
  "Candle Maker",
  "Cheesemaker",
  "Couponer",
  "Crocheter",
  "Sky Diver",
  "DJ",
  "DIY-er",
  "Engraver",
  "Mad Scientist",
  "Fishfarmer",
  "Gamer",
  "Genealogist",
  "Glassblower",
  "Gunsmith",
  "Hacker",
  "Hula Hooper",
  "Comedian",
  "Inventor",
  "Jewelry Maker",
  "Jigsaw Puzzlist",
  "Lego Engineer",
  "Livestreamer",
  "Masseuse",
  "Gardener",
  "Metalworker",
  "Miniature Art Artist",
  "Minimalist",
  "Pet Sitter",
  "Powerlifter",
  "Practical Jokester",
  "Rubik's Cube Solver",
  "Scuba Diver",
  "Seamstress",
  "Singer",
  "Rope Skipper",
  "Soap Maker",
  "Sudoku Solver",
  "Ping Pong Wiz",
  "Whittler",
  "Wikipedia Editor",
  "Zoo Visitor",
  "Amateur Geologist",
  "Bonsai Caretaker",
  "Spelunker",
  "Celebrity Impersonator",
  "Battle Reenactor",
  "Antiquer",
  "Meat Smoker",
  "Coin Collector",
  "Postcard Collector",
  "Knife Thrower",
  "First Edition Book Collector",
  "Balloon Twister",
  "Leaf Collector",
  "Cliff Diver",
  "Video Gamer",
  "Ax Thrower"
]

export const EMOTIONS = [
  "Ambivalent",
  "Brave",
  "Pleasant",
  "Retired",
  "Practicing",
  "Willful",
  "Unhinged",
  "Rageful",
  "Cheerful",
  "Bored",
  "Delighted",
  "Jealous",
  "Irritated",
  "Infatuated",
  "Awkward",
  "Helpless",
  "Sympathetic",
  "Outraged",
  "Content",
  "Adoring",
  "Fond",
  "Distrusting",
  "Bitter",
  "Satisfied",
  "Inferior",
  "Excited",
  "Interested",
  "Confused",
  "Amused",
  "Overwhelmed",
  "Vengeful",
  "Enthusiastic",
  "Uncomfortable",
  "Isolated",
  "Dismayed",
  "Alienated",
  "Calm",
  "Melancholy",
  "Exhausted",
  "Intrigued",
  "Disgusted",
  "Curious",
  "Weary",
  "Hesitant",
  "Preoccupied",
  "Happy",
  "Loving",
  "Scared",
  "Envious",
  "Tender",
  "Compassionate",
  "Alarmed",
  "Shamed",
  "Restless",
  "Exasperated",
  "Disoriented",
  "Neglected",
  "Frustrated",
  "Defeated",
  "Disillusioned",
  "Cautious",
  "Disturbed",
  "Spiteful",
  "Shocked",
  "Exhilarated",
  "Optimistic",
  "Guilty",
  "Amazed",
  "Regretful",
  "Stunned",
  "Insecure",
  "Insulted",
  "Relieved",
  "Indifferent",
  "Hopeful",
  "Pitiful",
  "Confident",
  "Contemptful",
  "Fearful",
  "Sorrowful",
  "Uncertain",
  "Annoyed",
  "Disappointed",
  "Caring",
  "Concerned",
  "Panicked",
  "Disgraced",
  "Nervous",
  "Tender",
  "Receptive",
  "Hateful",
  "Elated",
  "Trusting",
  "Depressed",
  "Hopeless",
  "Pleased",
  "Eager",
  "Angry",
  "Worried",
  "Horrified",
  "Grumpy",
  "Trustful",
  "Powerless",
  "Scornful",
  "Lonely",
  "Sad",
  "Comfortable",
  "Anguished",
  "Self conscious",
  "Aggravated",
]