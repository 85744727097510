import './Experience.css'
import { motion } from 'framer-motion'
import { fadeUp } from '../constants/animations'
import { ReactComponent as CatText } from '../images/cat-text.svg'
import cat from '../images/me+cat.png'
import PageTitle from '../components/PageTitle'
import ButtonLink from '../components/ButtonLink'
import TechCollection from '../components/TechCollection'

function Experience(props) {
  return (
      <div style={{backgroundColor: props.color}}  className={"content-container content-container--about-me"}>
        <motion.div initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          exit={{ opacity: 0 }} className="content-container--inner">
        <PageTitle text={"About Me"} />
        <motion.p initial="hidden" animate="visible" variants={fadeUp} className="body-paragraph">Technologies I've worked with:</motion.p>
        <motion.div  initial="hidden" animate="visible" variants={fadeUp}>
          <TechCollection />
        </motion.div>
        <motion.p initial="hidden" animate="visible" variants={fadeUp} className="body-paragraph">I have 4 years of experience working as an in-house engineer for the digital nutrition health innovator, AeBeZe Labs, working across the stack making web apps, backend APIs, google home apps and chrome extensions, and serving as a liaison between developers, designers, and other members of the company. I enjoy solving interesting problems and employing new technologies. As the sole engineer at my current company I know how and where to find answers when I reach the limits of my own knowledge, but I yearn for a more richly collaborative environment with other engineers in my next position.</motion.p>
        <motion.p initial="hidden" animate="visible" variants={fadeUp} className="body-paragraph">I was born and raised in St. Paul Minnesota, and I graduated in the class of 2017 at Sarah Lawrence College. There I initially intended to study psychology, but almost immediately discovered and pivoted whole-heartedly to Computer Science. I worked my way through Sarah Lawrence's entire CS catalog and worked as a lab assistant and tutor to share my love of coding with other students. Today I live in San Francisco. Besides coding I enjoy rock-climbing, playing tennis, drawing, and getting together at the beach every Sunday with other husky dog rescue families!</motion.p>
        <motion.div initial="hidden" animate="visible" variants={fadeUp} >
          <ButtonLink text="Send me an email!" link="mailto:juliet.slade@gmail.com" hasShadow={true} />
        </motion.div>
        <div style={{marginBottom: '100px'}}></div>
        <div className="svg--cat-container--outer">
          <div className="svg--cat--container">
            <img className="svg--cat" src={cat}/>
            <CatText className="svg--cat" />
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default Experience